

import Component from 'vue-class-component';
import {Emit, PropSync, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {callStore} from '@/store/__STORE_call';
import QR from '@/components_v2/generic/misc/__COMP_QRCode.vue';

@Component({
  components: {
    QR,
  },
})
/**
 * Intelligent component to display a joining call QR code for the user depending on their access level
 * 'xr_host' |'xr_guest' | 'generic'
 */
export default class QRCode extends Vue {
  @PropSync('input', {type: String}) readonly __PROPinput?: string
  @PropSync('type', {type: String, default: 'generic'}) readonly __PROPtype: 'xr_guest' | 'xr_host' | 'generic'

  output = ''

  name: 'QR Code'
  /**
   * Generates the code and is rendered toi the screen
   */
  @Watch('type')
  @Watch('input')
  async generateCode() {
    const data: string = this.__PROPinput;

    switch (this.__PROPtype) {
      case 'xr_guest':
      case 'xr_host':
        // Assume input is alias
        // Request API to verifiy room exists
        if (!callStore.vmr.has_xr_license) {
          return this.__EMITerror('VMR Does Not Have XR License');
        }

        this.output = JSON.stringify({
          room: data,
          pin: this.__PROPtype == 'xr_host' ? callStore.vmr.webrtc.pin : callStore.vmr.webrtc.guest_pin,
        });

        break;
      case 'generic':
      default:
        this.output = data;
        break;
    }
  }

  /**
   *
   */
  mounted() {
    this.generateCode();
  }

  /**
   *   Fires when the QR Code is ready
   * @return {boolean}
   */
  @Emit('ready')
  __EMITready() {
    return true;
  }

  /**
   * Fires anytime there was an error
   *
   * @param {string} err
   * @return {string}
   */
  @Emit('error')
  __EMITerror(err:string) {
    return err;
  }
}
