<template>
  <main id="cvi_wrapper">
    <section class="section">
      <b-container class="mt-md-0 w-100 p-3 text-break">
        <b-row class="justify-content-center text-center text-md-left">
          <b-col
            md="2"
            class="align-self-center d-flex justify-content-md-end justify-content-center"
          >
            <icon-camera-small
              :fill-color="iconColor"
              :width="iconSize"
              :height="iconSize"
              class=""
            />
          </b-col>
          <b-col md="6">
            <h1>Join The Meeting Directly</h1>
            <p v-if="conf && d">
              From a VTC/SIP system, enter: <span
                id="sip"
                class="highlight"
              > {{ prefix }}{{ conf }}@{{ d }} </span>
            </p>
            <p v-else>
              {{ error_message }}
            </p>
            <p v-if="ip">
              Alternative addresses (e.g. H.323 systems): <span
                id="h323"
                class="highlight"
              > {{ conf }}@{{ ip }} </span> or <span class="highlight">{{ ip }}##{{ conf }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row class="">
          <div class="divider" />
        </b-row>

        <b-row class="justify-content-center text-center text-md-left">
          <b-col
            md="2"
            class="align-self-center d-flex justify-content-md-end justify-content-center"
          />
          <b-col md="6">
            <h1>Join via Lobby Service</h1>
            <p v-if="((ivr && d) || ip) && conf">
              From a VTC/SIP system, enter: <span
                v-if="ivr && d"
                id="sip_again"
                class="highlight"
              > {{ ivr }}@{{ d }} </span> <template v-if="ip && ivr && d">
                or
              </template> <template v-if="ip">
                <span
                  id="ip"
                  class="highlight"
                >{{ ip }}</span>
              </template> then enter the conference ID: <span
                id="conf"
                class="highlight"
              >{{ conf }}</span> followed by <span class="highlight">#</span>
            </p>
            <p v-else>
              {{ error_message }}
            </p>
          </b-col>
        </b-row>
        <b-row class="">
          <div class="divider" />
        </b-row>

        <b-row
          v-if="conf"
          id="wearables"
          class="justify-content-center text-center text-md-left"
        >
          <b-col
            md="2"
            class="align-self-center d-flex justify-content-md-end justify-content-center"
          />
          <b-col md="6">
            <h1>Wearables</h1>
            <p>Join from a wearable device</p>
            <qrcode
              :input="roomForQRCode"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="conf"
          class=""
        >
          <div class="divider" />
        </b-row>
        <b-row class="justify-content-center text-center text-md-left">
          <b-col
            md="2"
            class="align-self-center d-flex justify-content-md-end justify-content-center"
          />
          <b-col md="6">
            <h1>Test Call</h1>
            <p v-if="conf && test">
              To test your connection from a VTC/SIP system, enter: <span
                id="test"
                class="highlight"
              > {{ test }}@{{ d }}</span> and verify that you can see and hear yourself
            </p>
            <p v-else>
              {{ error_message }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footer
      class="d-flex justify-content-between"
    >
      <img
        id="sv"
        src="/img/PoweredbySimplyVideo.jpg"
      >
      <img
        v-if="showPexipLogo"
        id="pexip"
        src="/img/Pexip Powered Logo White RGB@2x.png"
      >
    </footer>
  </main>
</template>
<script>
import * as Icons from '@/components/icons';
import qrcode from '@/components_v2/intelligent/branding/__COMP_QRCode.vue';

export default {
  components: {
    ...Icons,
    qrcode,
  },
  data() {
    return {
      d: String,
      conf: String,
      test: String,
      ip: String,
      ivr: String,
      prefix: String,
      error_message: 'Details not provided for this feature',
      iconSize: '50px',
      iconColor: 'none',
      brand_hero: '',
    };
  },
  computed: {
    showPexipLogo() {
      return process.env.NODE_ENV == 'production';
    },
    roomForQRCode() {
      if (this.prefix && this.prefix != '') {
        return JSON.stringify({room: this.prefix + this.conf + '@' + this.d});
      }
      return JSON.stringify({room: this.conf + '@' + this.d});
    },
  },
  mounted() {
    // Get url queries
    // Populate page
    const {d, conf, test, ip, ivr, prefix} = this.$route.query;

    this.ip = ip;
    this.d = d;
    this.conf = conf;
    this.test = test;
    this.ivr = ivr;
    this.prefix = prefix;
  },
  methods: {
    showMissingBrandImage(evt) {
      // alert();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#cvi_wrapper {
  padding-top: 20px;
}
section.section {
  overflow-y: auto;
  padding: 10px 1rem 50px;
}

@include media-breakpoint-down(xs) {
  section.section {
    margin-top: -51px;
  }
}

footer {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 60px;
  max-height: 75px;
  bottom: 0px;
  padding: 10px;

  > img {
    height: 100%;
    width: auto;
  }

  #pexip {
    opacity: 0.65;
  }
}

h1 {
  font-size: 2em;
  font-weight: bold;
}

.divider {
  background-color: rgba(90, 90, 90, 0.199);
  display: block;
  height: 1px;
  margin: 20px auto;
  width: 50%;
}

@media only screen and (max-width: 800px) {
  h1{
    text-align: center;
    font-size: 20px;
  }
}
</style>
